import React from 'react'
import { MDXTag } from '@mdx-js/tag'




export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}><MDXTag name="p" components={components}>{`OtoSim Educators Portal™ and OtoSim Mobile™ can also be used with
OtoSim 2™ Otoscopy Simulator to further enhance learning outcomes.`}</MDXTag></MDXTag>

export const _frontmatter = {};

  