import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import { Heading} from 'grommet';

export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}>
<Heading level="3" size="small" responsive={true} alignSelf="center">
    Key features:
</Heading>
<MDXTag name="p" components={components}>{`The OtoSim Educators Portal™ is designed specifically for clinical educators who teach otology as part of a medical education curriculum, a clinical training program, or a CME course. The OtoSim Educators Portal™ gives clinical educators the full control over their otology curriculum to:`}</MDXTag>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">{`Easily create and customize lectures, quizzes, and exams`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Connect with students who are using OtoSim Mobile™`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Track and monitor performance of students in real-time`}</MDXTag>
</MDXTag></MDXTag>

export const _frontmatter = {};

  