import React from 'react'
import { MDXTag } from '@mdx-js/tag'




export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}><MDXTag name="p" components={components}>{`“Preclerkship medical student acquisition and retention of otolaryngology diagnostic skills can be greatly improved through web-based teaching modules and otoscopy simulation.”`}</MDXTag>
<MDXTag name="p" components={components}>{`Wu, V. and Beyea, JA. Otolaryngology -
Head and Neck Surgery. Vol 156, Issue 2, 2017`}</MDXTag></MDXTag>

export const _frontmatter = {};

  