import React from 'react'
import { MDXTag } from '@mdx-js/tag'




export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}><MDXTag name="p" components={components}>{`OtoSim Educators Portal™ is an online educational platform for mobile otoscopy simulation.`}</MDXTag></MDXTag>

export const _frontmatter = {};

  