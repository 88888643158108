import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import { Heading} from 'grommet';

export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}>
<Heading level="3" size="small" responsive={true} alignSelf="center">
    Track and monitor performance of students:
</Heading>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">{`Connect with your trainees via OtoSim MobileTM`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Tag chapters, topics, and quizzes for students to review`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Track and monitor individual and group performance in real time`}</MDXTag>
</MDXTag></MDXTag>

export const _frontmatter = {};

  